<template>
	<div>
		<div class="mx-2 m-2 position-relative">
			<b-row>
				<b-col
					cols="12"
					md="12"
					lg="6"
					class="d-flex align-items-center justify-content-start justify-content-sm-start"
				>
					<span class="text-muted">
						Mostrando {{ start_page }} a {{ to_page }} de {{ total_data }} registros
					</span>
				</b-col>
				<b-col cols="12" md="12" lg="6" class="flex-nowrap">
					<div class="flex flex-wrap cross-center w-full gap-x-3 xt-search-input justify-content-end">
						<div class="xt-search-input__paginate">
							<b-pagination
								v-model="current_page"
								:total-rows="total_data"
								:per-page="perpage"
								first-number
								last-number
								prev-class="prev-item"
								next-class="next-item"
							>
								<template #prev-text>
									<feather-icon icon="ChevronLeftIcon" size="18" />
								</template>
								<template #next-text>
									<feather-icon icon="ChevronRightIcon" size="18" />
								</template>
							</b-pagination>
						</div>
					</div>
				</b-col>
			</b-row>
		</div>

		<b-table-simple sticky-header class="table mb-0" :style="{ minHeight: '70vh' }">
			<b-thead>
				<b-tr class="text-center">
					<b-th class="p-50">#</b-th>
					<b-th class="p-50"> {{ madrilla_id ? 'Padrillos' : 'Madrillas' }} </b-th>
					<b-th class="p-50">Fecha</b-th>
					<b-th class="p-50">Estado</b-th>
				</b-tr>
			</b-thead>
			<b-tbody>
				<b-tr class="text-center" v-for="(row, i) in items" :key="row.value">
					<b-td class="p-50">
						{{ i + 1 }}
					</b-td>
					<b-td>
						<SpecimenPlate
							v-if="madrilla_id"
							:specimen="{
								id: row.padrillo.id,
								plate: row.padrillo.plate,
								alias: row.padrillo.alias,
								thumb: row.padrillo.thumb,
								image: row.padrillo.url
							}"
						/>
						<SpecimenPlate
							v-if="padrillo_id"
							:specimen="{
								id: row.madrilla.id,
								plate: row.madrilla.plate,
								alias: row.madrilla.alias,
								thumb: row.madrilla.thumb,
								image: row.madrilla.url
							}"
						/>
					</b-td>
					<b-td class="p-50">
						{{ row.date | myGlobalDayShort }}
					</b-td>
					<b-td class="p-50" :class="row.status_id == 2 ? 'text-danger' : ''">
						{{ row.status }}
					</b-td>
				</b-tr>
				<b-tr class="text-center font-weight-bold" v-if="items.length == 0">
					<b-td colspan="4">No hay datos para mostrar</b-td>
				</b-tr>
			</b-tbody>
		</b-table-simple>
	</div>
</template>

<script>
import fertilityService from "../fertilidad.service";
import SpecimenPlate from "@/components/specimens/SpecimenPlate.vue";

export default {
	name: "TrackingMadrillas",
	components: {
		SpecimenPlate,
	},
	props: {
		madrilla_id: {
			type: Number,
		},
		padrillo_id: {
			type: Number,
		},
	},
	data() {
		return {
			items: [],
			start_page: 1,
			to_page: 1,
			total_data: 0,
			current_page: 1,
			perpage: 40,
		};
	},
	async mounted() {
		await this.init();
	},
	methods: {
		async init() {
			const [{ end, start }] = this.seasons;

			this.isPreloading();

			if (this.madrilla_id) {
				const {
					data: { data, from, last_page, to, total },
				} = await fertilityService.getHistoryByMadrilla({
					madrilla_id: this.madrilla_id,
					end_date: end,
					start_date: start,
					page: this.current_page,
					perpage: this.perpage,
				});
				this.items = data;
				this.start_page = from;
				this.to_page = to;
				this.total_data = total;
			}
			if (this.padrillo_id) {
				const {
					data: { data, from, last_page, to, total },
				} = await fertilityService.getHistoryByPadrillo({
					padrillo_id: this.padrillo_id,
					end_date: end,
					start_date: start,
					page: this.current_page,
					perpage: this.perpage,
				});
				this.items = data;
				this.start_page = from;
				this.to_page = to;
				this.total_data = total;
			}

			this.isPreloading(false);
		},
	},
	watch: {
		async current_page() {
			await this.init();
		},
	},
};
</script>
