<template>
	<div class="container-history">
		<div class="card date" :class="[currentDate(date.date) ? 'now-date' : '']">
			<p>{{ date.date | dayWithMonth }}</p>
			<p class="now" v-if="currentDate(date.date)">Hoy</p>
			<p v-else>{{ getDay(date.date) }}</p>
		</div>
		<div class="card padrillos total">
			<div class="card padrillos" v-if="tab != 1 || (tab == 1 && getPendings > 0)">
				<template v-if="date.padrillos.length > 0">
					<template v-for="specimen in date.padrillos">
						<div
							v-if="tab != 1 || (tab == 1 && specimen.insem_counter == 0)"
							v-b-tooltip.hover
							:title="dateNow >= date.date ? 'Ver inseminación' : 'Programación futura'"
							:key="specimen.id"
							class="container-skeletor"
						>
							<NewCardSpecimen :specimen="{ ...specimen, category_id: 1 }" />
							<div
								@click="selectPadrillo(specimen)"
								class="skeletor pointer"
								:style="{
									borderTop: `5px solid ${specimen.status_color}`,
								}"
							></div>
							<div class="div-type-ins" v-if="specimen.specimen_foot_hand != null">
								<b-badge
									variant="light-primary"
									v-b-tooltip.hover
									:title="resolveTypeIns(specimen.specimen_foot_hand).message"
								>
									{{ resolveTypeIns(specimen.specimen_foot_hand).title }}
								</b-badge>
							</div>
						</div>
					</template>
				</template>
				<template v-else>
					<div class="d-flex align-items-center" style="height: 60px">
						<p v-if="date.feature_days" class="mb-0 font-weight-bold text-center">
							Sin programaciones para esta fecha
						</p>
					</div>
				</template>
			</div>

			<div class="card padrillos" v-if="tab == 1 && getIncomplete">
				<template v-if="date.padrillos.length > 0">
					<template v-for="specimen in date.padrillos">
						<div
							v-if="specimen.insem_counter && specimen.insem_counter > 0"
							v-b-tooltip.hover
							:title="dateNow >= date.date ? 'Ver inseminación' : 'Programación futura'"
							:key="specimen.id"
							class="container-skeletor"
						>
							<NewCardSpecimen :specimen="{ ...specimen, category_id: 1 }" />
							<div
								@click="selectPadrillo(specimen)"
								class="skeletor pointer"
								:style="{
									borderTop: `5px solid ${specimen.status_color}`,
								}"
							></div>
							<div class="div-type-ins" v-if="specimen.specimen_foot_hand != null">
								<b-badge
									variant="light-primary"
									v-b-tooltip.hover
									:title="resolveTypeIns(specimen.specimen_foot_hand).message"
								>
									{{ resolveTypeIns(specimen.specimen_foot_hand).title }}
								</b-badge>
							</div>
						</div>
					</template>
				</template>
				<template v-else>
					<div class="d-flex align-items-center" style="height: 60px">
						<p v-if="date.feature_days" class="mb-0 font-weight-bold text-center">
							Sin programaciones para esta fecha
						</p>
					</div>
				</template>
			</div>
		</div>
		<!-- asdasadssad -->
		<b-modal
			centered
			@hide="closeModalMadrillas"
			v-model="modalTrackingPadrillo.show"
			:title="modalTrackingPadrillo.title"
			:size="widthScreen >= 1200 ? 'xmd' : 'xlg'"
		>
			<div class="table-responsive mb-0">
				<div class="d-flex">
					<b-card class="text-center rounded-lg">
						<div class="d-flex flex-column gap-y-2">
							<SpecimenPlate :specimen="modalTrackingPadrillo.specimen" />
							<ChangeSpecimenEggEater
								dashboard
								:info="modalTrackingPadrillo.specimen"
								:type="'padrillo'"
								:disabled="true"
							/>
						</div>
					</b-card>
				</div>
				<b-table-simple sticky-header="50vh" class="table mb-0">
					<b-thead>
						<b-tr class="">
							<b-th class="position-relative">
								<div
									class="d-flex align-items-center justify-content-center cursor-pointer top-50 start-0 translate-middle-y"
								>
									<!-- <p class="mb-0 mr-1"></p> -->
									<!-- {{  checkTotalMadrillas}} -->
									<b-form-checkbox
										v-model="checkTotalMadrillas"
										class="d-flex align-items-center"
										@input="changeCheckTotalMadrillas"
									>
										Inseminar
									</b-form-checkbox>
								</div>
							</b-th>
							<b-th>
								<div class="d-flex align-items-center justify-content-center cursor-pointer">
									<p class="mb-0 mr-1">Madrillas</p>
								</div>
							</b-th>
							<b-th>
								<div class="d-flex align-items-center justify-content-center cursor-pointer">
									<p class="mb-0 mr-1">Tipo</p>
								</div>
							</b-th>
							<b-th>
								<div class="d-flex align-items-center justify-content-center cursor-pointer">
									<p class="mb-0 mr-1 text-center">Última inseminación</p>
								</div>
							</b-th>
						</b-tr>
					</b-thead>
					<b-tbody>
						<b-tr class="text-center" v-for="(item, index) in madrillas" :key="index">
							<b-td class="">
								<!-- {{ item.madrilla.is_pending }} -->
								<input
									:disabled="modalTasksPadrillo.status_id != 1 && !item.madrilla.is_pending"
									class="custom__checkbox"
									type="checkbox"
									v-model="item.checked"
									:class="[item.madrilla.is_pending ? 'cursor-pointer' : 'bg-light']"
								/>
							</b-td>

							<b-td class="">
								<div class="relative">
									<div class="d-flex align-items-center justify-content-end">
										<font-awesome-icon
											v-if="item.eggs_madrilla_id"
											icon="fa-solid fa-egg"
											size="xl"
											class="text-warning mr-3"
											v-b-tooltip.hover.top="'Huevo registrado'"
										/>
									</div>
									<SpecimenPlate
										:specimen="{
											id: item.madrilla.id,
											plate: item.madrilla.plate,
											alias: item.madrilla.alias,
											thumb: item.madrilla.thumb,
											image: item.madrilla.url,
										}"
									/>
								</div>
							</b-td>
							<b-td class="">
								{{ item.madrilla.method }}
							</b-td>
							<b-td class="" v-if="item.madrilla.last_insemination">
								<div class="d-flex flex-column align-items-center justify-content-center">
									<span>
										{{ item.madrilla.last_insemination | myGlobalDayShort }}
									</span>
									<span class="text-primary font-weight-bold" v-if="!item.madrilla.is_pending">
										{{ "Inseminado" }}
									</span>
								</div>
							</b-td>
							<b-td v-else>
								{{ "Sin inseminación" }}
							</b-td>
						</b-tr>
						<b-tr v-if="madrillas.length == 0">
							<b-td colspan="7" class="text-center">
								<p class="mb-0 font-weight-bold">Sin madrillas encontradas</p>
							</b-td>
						</b-tr>
					</b-tbody>
				</b-table-simple>
			</div>

			<template #modal-footer>
				<div class="d-flex justify-content-between w-100">
					<div class="d-flex justify-content-between w-100">
						<div
							class="d-flex align-items-start align-items-md-center mb-md-50 flex-column flex-md-row"
							v-if="modalTasksPadrillo.created"
						>
							<p class="mb-0 font-weight-bold">Registrado por:</p>
							<div class="ml-md-1 d-flex">
								<p class="mb-0 mr-50">{{ modalTasksPadrillo.created.user }}</p>
								<p class="mb-0 font-weight-bold">{{ modalTasksPadrillo.created.date }}</p>
							</div>
						</div>
					</div>
					<b-button
						variant="primary"
						:disabled="modalTasksPadrillo.status_id != 1 && dateNow != date.date"
						@click="sendSelection"
					>
						Guardar
					</b-button>
				</div>
			</template>
		</b-modal>

		<b-modal v-model="modalTasksPadrillo.show" size="lg">
			<template slot="modal-header">
				<div class="header-modal">
					<h4 class="mb-0">{{ modalTasksPadrillo.title }}</h4>
					<div
						v-b-tooltip.hover
						:title="modalTasksPadrillo.status_name"
						class="circle"
						:style="{ backgroundColor: modalTasksPadrillo.status_color }"
					></div>
					<div
						v-if="modalTasksPadrillo.leftover_madrillas"
						class="d-flex align-items-center"
						v-b-tooltip.hover
						title="Madrillas extras"
					>
						<img src="/encaste/hen.png" style="transform: scale(0.6) translateX(2px)" />
						<p class="mb-0 font-weight-bolder">{{ modalTasksPadrillo.leftover_madrillas }}</p>
					</div>
					<button @click="modalTasksPadrillo.show = false" type="button" aria-label="Close" class="close">
						×
					</button>
				</div>
			</template>

			<b-table-simple sticky-header class="table mb-0" :style="{ minHeight: '70vh' }">
				<b-thead>
					<b-tr class="text-center">
						<b-th class="p-50">#</b-th>
						<b-th class="p-50">Madrillas</b-th>
						<b-th class="p-50">Tipo</b-th>
						<b-th class="p-50">Estado</b-th>
					</b-tr>
				</b-thead>
				<b-tbody>
					<b-tr class="text-center" v-for="(row, i) in modalTasksPadrillo.data" :key="row.value">
						<b-td class="p-50">
							{{ i + 1 }}
						</b-td>
						<b-td>
							<div>
								<div class="d-flex align-items-center justify-content-end">
									<font-awesome-icon
										v-if="row.is_register_eggs"
										icon="fa-solid fa-egg"
										size="xl"
										class="text-warning mr-3"
										v-b-tooltip.hover.top="'Huevo registrado'"
									/>
								</div>
								<SpecimenPlate :specimen="row.madrilla" />
							</div>
						</b-td>
						<b-td class="p-50">
							<p class="mb-0">
								{{ row.method }}
							</p>
						</b-td>
						<b-td class="p-50">
							<p
								class="mb-0"
								:class="[row.status_id == 2 ? 'text-danger font-weight-bold' : 'text-secondary']"
							>
								{{ row.status }}
							</p>
						</b-td>
					</b-tr>
					<b-tr class="text-center font-weight-bold" v-if="modalTasksPadrillo.data.length == 0">
						<b-td colspan="3">No hay datos para mostrar</b-td>
					</b-tr>
				</b-tbody>
			</b-table-simple>

			<template #modal-footer>
				<div class="d-flex justify-content-between w-100">
					<div
						class="d-flex align-items-start align-items-md-center mb-md-50 flex-column flex-md-row"
						v-if="modalTasksPadrillo.created"
					>
						<p class="mb-0 font-weight-bold">Registrado por:</p>
						<div class="ml-md-1 d-flex">
							<p class="mb-0 mr-50">{{ modalTasksPadrillo.created.user }}</p>
							<p class="mb-0 font-weight-bold">
								{{ modalTasksPadrillo.created.date | myGlobalDayShort }}
							</p>
						</div>
						<p class="mb-0 ml-2 font-weight-bold">Inseminado por:</p>
						<div class="ml-md-1 d-flex align-items-end" v-if="modalTasksPadrillo.data.length">
							<p class="mb-0 mr-50">{{ modalTasksPadrillo.data[0].created_by.name }}</p>
							<p class="mb-0 font-weight-bold">
								{{ modalTasksPadrillo.data[0].created_by.date | myGlobalDayWithHour }}
							</p>
						</div>
						<span class="ml-1" v-else>---</span>
						<!-- <span>{{ row.created_by.name }}</span>
							<br />
							<small>{{ row.created_by.date | myGlobalDayWithHour }}</small> -->
					</div>
				</div>
			</template>
		</b-modal>

		<b-modal
			centered
			v-model="modalSendQtyMadrillas.show"
			:title="modalSendQtyMadrillas.title"
			size="xs"
			hide-footer
		>
			<b-form-group label="Cantidad" class="text-center">
				<vue-number-input
					v-model="modalSendQtyMadrillas.num"
					:min="1"
					inline
					controls
					style="max-width: 250px; margin-top: 5px"
					class="text-center"
				/>
			</b-form-group>
			<div class="d-flex align-items-center justify-content-end w-100">
				<button class="btn btn-primary" @click="sendInseminations(4)">Guardar</button>
			</div>
		</b-modal>
	</div>
</template>

<script>
import SpecimenPlate from "@/components/specimens/SpecimenPlate.vue"
import { backgroundColor } from "echarts/lib/theme/dark"
import moment from "moment"
import fertilidadService from "../fertilidad.service"
import NewCardSpecimen from "./NewCardSpecimen.vue"
import ChangeSpecimenEggEater from "@/views/amg/specimens/ChangeSpecimenEggEater.vue"
import { mapMutations } from "vuex"
import { bus } from "@/main"
import dashboard from "@/navigation/horizontal/dashboard"

export default {
	name: "TabHistoryItem",
	components: {
		SpecimenPlate,
		NewCardSpecimen,
		ChangeSpecimenEggEater,
	},
	props: {
		date: {
			type: Object,
		},
		tab: { type: Number },
	},
	data() {
		return {
			modalTrackingPadrillo: {
				show: false,
				specimen: {},
				title: null,
				id: null,
			},
			madrillas: [],
			curPadrillo: null,
			modalTasksPadrillo: {
				show: false,
				title: null,
				data: [],
				status_color: null,
				status_name: null,
				leftover_madrillas: null,
			},
			dateNow: moment(new Date()).format("YYYY-MM-DD"),
			checkTotalMadrillas: false,
			modalSendQtyMadrillas: {
				show: false,
				title: "Madrillas extras",
				num: 1,
			},
		}
	},
	computed: {
		widthScreen() {
			return this.$store.state.app.windowWidth
		},
		getPendings() {
			if (!this.date) return 0
			return this.date.padrillos.filter((it) => it.insem_counter == 0).length
		},
		getIncomplete() {
			if (!this.date) return 0
			return this.date.padrillos.filter((it) => it.insem_counter > 0).length
		},
	},
	methods: {
		...mapMutations("encasteInsemination", ["GET_COUNTER_PENDINGS"]),
		currentDate(date) {
			return moment(date).format("YYYY-MM-DD") == moment().format("YYYY-MM-DD")
		},
		getDay(date) {
			return moment(date).locale("es").format("dddd")
		},
		resolveTypeIns(status) {
			let title = { title: "", message: "" }
			switch (status) {
				case 1:
					title.title = "N"
					title.message = "Natural"
					break
				case 2:
					title.title = "P"
					title.message = "Pisa en mano"
					break
				default:
					title.title = "A"
					title.message = "Artificial"
					break
			}
			return title
		},
		async selectPadrillo(specimen) {
			// console.log("spe", specimen);

			if (specimen.status_id == 1 || specimen.status_id == 6) {
				this.isPreloading()
				const { data } = await fertilidadService.getAvailableMadrillasForPadrillo({
					padrillo_id: specimen.id,
				})

				this.isPreloading(false)

				this.madrillas = data.map((madrilla) => ({ ...madrilla, checked: false }))

				this.curPadrillo = specimen
				this.modalTrackingPadrillo.show = true
				this.modalTrackingPadrillo.specimen = specimen
				this.modalTrackingPadrillo.title = `Madrillas por inseminar / Padrillo (${
					specimen.alias || specimen.plate
				})`
				this.modalTrackingPadrillo.created = specimen.created
			} else {
				console.log("otras", specimen)
				// Otra api
				this.isPreloading()
				this.modalTasksPadrillo.show = true
				this.modalTasksPadrillo.title = `${specimen.alias ? specimen.alias : ""} (${specimen.plate})`
				this.modalTasksPadrillo.status_color = specimen.status_color
				this.modalTasksPadrillo.status_name = specimen.status_name
				this.modalTasksPadrillo.status_id = specimen.status_id
				this.modalTasksPadrillo.created = specimen.created
				this.modalTasksPadrillo.leftover_madrillas = specimen.leftover_madrillas

				const { data } = await fertilidadService.getMadrillasInseminatedByDateAndPadrillo({
					date: this.date.date,
					padrillo_id: specimen.id,
				})
				this.modalTasksPadrillo.data = data
				this.isPreloading(false)
			}
		},
		closeModalMadrillas() {
			this.checkTotalMadrillas = false
			this.madrillas = []
		},
		async sendSelection() {
			let madrillas = this.madrillas.map((item) => ({
				madrilla_id: item.madrilla.id,
				postura_id: item.postura_id,
				check: item.checked,
				plate: item.madrilla.plate,
				alias: item.madrilla.alias,
				is_pending: item.madrilla.is_pending,
			}))

			const { isConfirmed } = await this.showConfirmSwal({
				title: `¿Estás seguro de inseminar a las madrillas (${madrillas
					.filter((it) => it.check)
					.map((it) => it.plate)})?`,
				text: "¡No podrás revertir esto!",
			})
			if (!isConfirmed) return

			madrillas = madrillas.map((item) => ({
				...item,
				check: item.is_pending == 0 ? true : item.check,
			}))

			let status = 3
			let notFull = madrillas.some((madrilla) => !madrilla.check)
			if (!notFull) {
				const { isConfirmed } = await this.$swal({
					title: "Todas las madrillas inseminadas",
					text: "¿ESPERMA EXTRA?",
					icon: "question",
					showCancelButton: true,
					confirmButtonText: "Si",
					cancelButtonText: "No",
					allowOutsideClick: false,
					customClass: {
						cancelButton: "btn ml-1",
						confirmButton: "btn btn-primary",
					},
				})
				if (isConfirmed) {
					status = 4
					this.modalSendQtyMadrillas.show = true
				} else {
					status = 2
					this.sendInseminations(status)
				}
			} else {
				this.sendInseminations(status)
			}
		},

		async sendInseminations(status) {
			let madrillas = this.madrillas.map((item) => ({
				madrilla_id: item.madrilla.id,
				postura_id: item.postura_id,
				check: item.checked,
				plate: item.madrilla.plate,
				alias: item.madrilla.alias,
				is_pending: item.madrilla.is_pending,
			}))
			// para validar cuando en la primera inseminacion sobra semen
			madrillas = madrillas.map((item) => ({
				...item,
				check: item.is_pending == 0 ? true : item.check,
			}))
			await fertilidadService.insertPosturaFromEncastador({
				padrillo_id: this.curPadrillo.id,
				madrillas,
				status_id: status,
				insemination_id: this.curPadrillo.insemination_id,
				leftover_madrillas: status == 2 ? null : this.modalSendQtyMadrillas.num,
			})
			this.modalSendQtyMadrillas.show = false
			this.reload()
			this.GET_COUNTER_PENDINGS()
			this.showSuccessToast("Registro guardado exitosamente")
		},

		reload() {
			this.modalTrackingPadrillo = {
				show: false,
				title: null,
				specimen: {},
				id: null,
			}
			this.curPadrillo = null
			this.$emit("refresh")
		},
		changeCheckTotalMadrillas() {
			for (let index = 0; index < this.madrillas.length; index++) {
				const element = this.madrillas[index]
				this.madrillas[index].madrilla.is_pending == 1 &&
					(this.madrillas[index].checked = this.checkTotalMadrillas)
			}
		},
	},
}
</script>

<style lang="scss">
.container-overflow {
	overflow: auto;
}
.container-history {
	display: grid;
	grid-template-columns: 1.5fr 10fr;
	gap: 0.5rem;
	.date {
		border-left: 5px solid var(--primary);
		padding: 0.5rem;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-bottom: 0.5rem;
		p {
			margin-bottom: 0;
		}
		width: 120px;
		@media screen and (min-width: 800px) {
			width: 100%;
			padding: 1rem;
		}
	}
	.now-date {
		.now {
			color: aqua;
			font-weight: bolder;
		}
	}
	.padrillos {
		margin-bottom: 0.5rem;
		padding: 1rem;
		display: flex !important;
		flex-direction: row;
		max-width: 100%;
		overflow: auto;
		.container-skeletor {
			// background: red;
			position: relative;
			margin-right: 1rem;
			padding: 0.5rem;
			// overflow: h;
			cursor: pointer !important;
		}
		.skeletor {
			border-radius: 8px;
			box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
			cursor: pointer !important;
			position: absolute;
			top: 0;
			bottom: 0;
			right: 0;
			left: 0;
			transition: 0.3s ease-in-out;
			// background: red;
			&:hover {
				background: rgba(0, 0, 0, 10%);
			}
		}
	}

	.total {
		flex-direction: column !important;
	}
}
.circle {
	border-radius: 50%;
	width: 18px;
	height: 18px;
	// position: absolute;
}

.custom__checkbox {
	margin-right: 0.5rem;
	display: inline-block;
	// cursor: pointer;
	border-radius: 0.2rem;
	background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
	width: 2rem;
	height: 2rem;
	appearance: none;
	border: 2px solid var(--gray);
	background-position: 0 -2rem;
	background-size: 100%;
	background-repeat: no-repeat;
	transition: all 0.2s ease-in-out;

	&:checked {
		background-color: var(--primary);
		color: var(--primary);
		background-position: 0 0;
	}
}

.header-modal {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	position: relative;
	width: 100%;
	.circle {
		margin-left: 1rem;
		height: 20px;
		width: 20px;
		border-radius: 50%;
		// background: var(--secondary);
	}
	.close {
		position: absolute;
		top: -18px;
		right: -7px;
	}
}
.div-type-ins {
	position: relative;
	width: 25px;
	top: -99px;
	right: 0;
}
</style>
