import { render, staticRenderFns } from "./TabHistory.vue?vue&type=template&id=566bd57c"
import script from "./TabHistory.vue?vue&type=script&lang=js"
export * from "./TabHistory.vue?vue&type=script&lang=js"
import style0 from "./TabHistory.vue?vue&type=style&index=0&id=566bd57c&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports