<template>
	<div>
		<div class="d-flex justify-content-center gap-x-1" v-if="type === 'madrilla'">
			<FeatherIcon
				icon="CheckCircleIcon"
				size="20"
				class="text-danger"
				:class="{ 'cursor-pointer': !disabled }"
				@click="changeStatus"
				v-if="info.specimen_carnivorous === 1"
			/>
			<FeatherIcon
				icon="CircleIcon"
				size="20"
				:class="{ 'text-primary': dashboard, 'text-secondary': !dashboard, 'cursor-pointer': !disabled }"
				@click="changeStatus"
				v-else
			/>
			<FeatherIcon
				:icon="'InfoIcon'"
				size="20"
				class="text-secondary"
				:class="{ 'cursor-pointer': true }"
				@click="openTracking"
				v-if="info.view_tracking_specimen_carnivorous === 1"
			/>
		</div>

		<div class="d-flex align-items-center gap-x-3" v-else-if="type === 'padrillo'">
			<div class="d-flex flex-column justify-content-start gap-y-1">
				<div v-if="!disabled || (disabled && info.specimen_foot_hand === 1)" class="d-flex gap-x-1">
					<FeatherIcon
						:icon="`${info.specimen_foot_hand === 1 ? 'CheckCircleIcon' : 'CircleIcon'}`"
						size="20"
						:class="{
							'text-primary': info.specimen_foot_hand === 1,
							'text-secondary': info.specimen_foot_hand != 1,
							'cursor-pointer': !disabled && info.specimen_foot_hand != 1,
						}"
						@click="changeStatusForPadrillo(1)"
					/>
					<span
						:class="{
							'text-primary': info.specimen_foot_hand === 1,
							'font-weight-bold': info.specimen_foot_hand === 1,
						}"
					>
						Natural
					</span>
				</div>
				<!-- v-if="info.specimen_foot_hand === 1" -->
				<div class="d-flex gap-x-1" v-if="!disabled || (disabled && info.specimen_foot_hand === 2)">
					<FeatherIcon
						:icon="`${info.specimen_foot_hand === 2 ? 'CheckCircleIcon' : 'CircleIcon'}`"
						size="20"
						:class="{
							'text-primary': info.specimen_foot_hand === 2,
							'text-secondary': info.specimen_foot_hand != 2,
							'cursor-pointer': !disabled && info.specimen_foot_hand != 2,
						}"
						@click="changeStatusForPadrillo(2)"
					/>
					<span
						:class="{
							'text-primary': info.specimen_foot_hand === 2,
							'font-weight-bold': info.specimen_foot_hand === 2,
						}"
					>
						Pisa en mano
					</span>
				</div>

				<div v-if="!disabled || (disabled && info.specimen_foot_hand === 3)" class="d-flex gap-x-1">
					<FeatherIcon
						:icon="`${info.specimen_foot_hand === 3 ? 'CheckCircleIcon' : 'CircleIcon'}`"
						size="20"
						:class="{
							'text-primary': info.specimen_foot_hand === 3,
							'text-secondary': info.specimen_foot_hand != 3,
							'cursor-pointer': !disabled && info.specimen_foot_hand != 3,
						}"
						@click="changeStatusForPadrillo(3)"
					/>
					<span
						:class="{
							'text-primary': info.specimen_foot_hand === 3,
							'font-weight-bold': info.specimen_foot_hand === 3,
						}"
					>
						Artificial
					</span>
					<!-- :class="{ 'text-primary': dashboard, 'text-secondary': !dashboard, 'cursor-pointer': !disabled }" -->
				</div>
			</div>
			<FeatherIcon
				:icon="'ListIcon'"
				size="21"
				class="text-warning"
				:class="{ 'cursor-pointer': true }"
				@click="openTracking"
				v-if="info.view_tracking_specimen_foot_hand != null && info.view_tracking_specimen_foot_hand > 0"
			/>
		</div>

		<b-modal
			centered
			hide-footer
			:title="`Tracking ${
				type === 'padrillo'
					? 'tipo de inseminacion | Padrillo ' + (this.info.alias ? this.info.alias : this.info.plate)
					: 'come huevo'
			}`"
			v-model="tracking.show"
		>
			<b-overlay :show="tracking.loading">
				<b-table-simple small sticky-header="30vh">
					<b-thead class="text-center">
						<b-tr>
							<b-th>Estado</b-th>
							<b-th>Salida</b-th>
							<b-th>Entrada</b-th>
						</b-tr>
					</b-thead>
					<b-tbody class="text-center">
						<b-tr v-for="(row, idx) in tracking.rows" :key="idx">
							<b-td>
								{{
									String(type == "padrillo" ? getDescription(row.status_id) : row.name).toUpperCase()
								}}
							</b-td>
							<b-td>
								<template v-if="row.end_date">
									<span>{{ row.updated_by }}</span>
									<br />
									<small>{{ row.end_date | myGlobalDayShort }}</small>
								</template>
								<span v-else>---</span>
							</b-td>
							<b-td>
								<span>{{ row.created_by.name }}</span>
								<br />
								<small>{{ row.created_by.date | myGlobalDayShort }}</small>
							</b-td>
						</b-tr>
					</b-tbody>
				</b-table-simple>
			</b-overlay>
		</b-modal>
	</div>
</template>

<script>
import dashboard from "@/navigation/horizontal/dashboard"
import specimenService from "@/services/specimens.service"

export default {
	props: {
		info: Object,
		dashboard: Boolean,
		type: String,
		disabled: { type: Boolean, required: false, default: false },
	},
	data: () => ({
		tracking: {
			show: false,
			loading: false,
			rows: [],
		},
	}),
	methods: {
		getDescription(status) {
			let description = ""
			switch (status) {
				case 1:
					description = "Natural"
					break
				case 2:
					description = "Pisa en mano"
					break
				default:
					description = "Artificial"
					break
			}
			return description
		},
		async changeStatusForPadrillo(status) {
			if (this.disabled) return
			if (status == this.info.specimen_foot_hand) return

			const { isConfirmed } = await this.showConfirmSwal({
				title: `Se cambiara el tipo de inseminacion a: ${this.getDescription(status)}, del ejemplar ${
					this.info.alias || this.info.plate
				}`,
			})
			if (!isConfirmed) return
			this.isPreloading()
			await specimenService.changeStatusCarnivorous({
				specimens: [this.info.id],
				type: this.type,
				status: status,
			})
			this.isPreloading(false)
			this.$emit("refresh")
		},
		async changeStatus() {
			if (this.disabled) return
			const { isConfirmed } = await this.showConfirmSwal({
				title: `Se ${
					this.info.specimen_carnivorous || this.info.specimen_foot_hand ? "desactivara" : "activara"
				} la caracteristica ${this.type === "padrillo" ? "pisa en mano" : "come huevo"} del ejemplar ${
					this.info.alias || this.info.plate
				}`,
			})
			if (!isConfirmed) return
			this.isPreloading()
			await specimenService.changeStatusCarnivorous({ specimens: [this.info.id], type: this.type })
			this.isPreloading(false)
			this.$emit("refresh")
		},
		async openTracking() {
			this.tracking.show = true
			this.tracking.loading = true
			const { data } = await specimenService.trackingCarnivorous({ specimen_id: this.info.id, type: this.type })
			this.tracking.rows = data
			this.tracking.loading = false
		},
	},
}
</script>
