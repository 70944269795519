<template>
	<div>
		<div class="p-2 card align-items-center justify-content-between filters-container-insemination">
			<div class="d-flex align-items-center dates">
				<b-form-group label="Fecha inicial" class="form-group">
					<b-form-datepicker
						placeholder="Seleccionar"
						:date-format-options="{
							year: 'numeric',
							month: 'numeric',
							day: 'numeric',
						}"
						locale="es"
						v-model="start_date"
						:max="end_date"
						v-b-tooltip.hover
						title="Fecha de inicio"
						class="mr-1"
						@input="init"
					/>
				</b-form-group>

				<b-form-group label="Fecha final" class="form-group">
					<b-form-datepicker
						placeholder="Seleccionar"
						:date-format-options="{
							year: 'numeric',
							month: 'numeric',
							day: 'numeric',
						}"
						locale="es"
						v-model="end_date"
						:min="start_date"
						v-b-tooltip.hover
						title="Fecha final"
						@input="init"
					/>
				</b-form-group>

				<div class="d-flex align-items-center mb-1 mb-md-0">
					<b-pagination
						v-model="page"
						:total-rows="total_data"
						:per-page="perpage"
						first-number
						last-number
						prev-class="prev-item"
						next-class="next-item"
						class="mb-0"
					>
						<template #prev-text>
							<feather-icon icon="ChevronLeftIcon" size="18" />
						</template>
						<template #next-text>
							<feather-icon icon="ChevronRightIcon" size="18" />
						</template>
					</b-pagination>

					<FeatherIcon
						@click="reloadCalendar"
						icon="RefreshCcwIcon"
						size="18"
						class="text-primary cursor-pointer ml-1"
					/>
				</div>
			</div>
			<div class="container-btn" v-if="isSupervisor || isAdmin">
				<button @click="modalPosturasInsemination.show = true" class="btn btn-primary">
					Agregar programación
				</button>
			</div>
		</div>
		
		<template v-if="dates.length > 0">
			<TabHistoryItem @refresh="init" :date="date" :tab="statusTab" v-for="(date, index) in dates" :key="index" />
		</template>

		<template v-else>
			<div class="card p-2">
				<p class="mb-0 text-center font-weight-bolder">Sin fechas encontradas</p>
			</div>
		</template>

		<div class="card p-2 container-leyend mt-1">
			<h6>Leyenda</h6>
			<div class="leyend">
				<div class="item" v-for="(item, index) in statusArray" :key="index">
					<div class="circle" :style="{ backgroundColor: item.color }"></div>
					<p>{{ item.description }}</p>
				</div>
				<div class="item">
					<img src="/encaste/hen.png" style="transform: scale(0.6) translateX(-10px)" />
					<p style="margin: 0; margin-left: -6px">Madrillas extras</p>
				</div>
			</div>
		</div>

		<b-sidebar
			width="100%"
			scrollable
			v-model="modalPosturasInsemination.show"
			title="Agregar programación"
			size="xl"
			body-class="bg-wh p-0 m-0 side"
			hide-footer
			lazy
			@hidden="refreshTabPosturas"
		>
			<TabPosturas @refreshTabPosturas="refreshTabPosturas" />
		</b-sidebar>
	</div>
</template>

<script>
import { bus } from "@/main";
import TabHistoryItem from "./TabHistoryItem.vue";
import fertilityService from "../fertilidad.service";
import moment from "moment";
import TabPosturas from "./TabPosturas.vue";
import { initCalendarDates } from "@/helpers/calendar";
import { backgroundColor } from "echarts/lib/theme/dark";
import { mapState, mapMutations } from "vuex";

export default {
	name: "TabHistory",
	components: {
		TabHistoryItem,
		TabPosturas,
	},
	data() {
		return {
			dates: [],
			start_date: null,
			end_date: null,
			modalPosturasInsemination: {
				show: false,
				title: null,
			},
			weeks: 1,
			weekCurrent: 1,
			weeksData: [],
			statusArray: [],
			tabIndex: 0,
			page: 1,
			perpage: 14,
			start_page: 1,
			to_page: 1,
			total_data: 0,
		};
	},
	computed: {
		...mapState("encasteInsemination", ["pending"]),
		statusTab() {
			return this.$route.meta.status;
		},
	},
	async mounted() {
		await this.getStatus();
		await this.init();
		this.GET_COUNTER_PENDINGS();
	},
	methods: {
		...mapMutations("encasteInsemination", ["GET_COUNTER_PENDINGS"]),
		async init() {
			this.isPreloading();
			const {
				data: { data, from, last_page, to, total },
			} = await fertilityService.getReportInseminationByDate({
				start_date: this.start_date,
				end_date: this.end_date,
				status_id: this.statusTab,
				page: this.page,
				perpage: this.perpage,
			});
			// this.dates = data;
			this.isPreloading(false);

			this.dates = data;
			this.start_page = from;
			this.to_page = to;
			this.total_data = total;
		},
		async nextWeek() {
			this.start_date = moment(this.start_date).add(7, "days").format("YYYY-MM-DD");
			this.end_date = moment(this.end_date).add(7, "days").format("YYYY-MM-DD");
			await this.init();
		},
		async previousWeek() {
			this.start_date = moment(this.start_date).add(-7, "days").format("YYYY-MM-DD");
			this.end_date = moment(this.end_date).add(-7, "days").format("YYYY-MM-DD");
			this.page = 1;
			await this.init();
		},
		async refreshTabPosturas() {
			this.modalPosturasInsemination.show = false;
			this.start_date = null;
			this.end_date = null;
			await this.init();
			console.log("ADD");
		},
		async reloadCalendar() {
			// this.start_date = moment().startOf("week").format("YYYY-MM-DD");
			// this.end_date = moment().endOf("week").format("YYYY-MM-DD");
			await this.init();
			this.GET_COUNTER_PENDINGS();
		},
		async getStatus() {
			const { data } = await fertilityService.getStatus();
			this.statusArray = data;
		},
	},
	watch: {
		async statusTab() {
			await this.init();
		},
		async page() {
			await this.init();
		},
	},
};
</script>

<style lang="scss">
.filters-container-insemination {
	flex-direction: column;
	@media screen and (min-width: 500px) {
		flex-direction: row;
	}
	.dates {
		width: 100%;
		display: flex;
		flex-direction: column;
		@media screen and (min-width: 500px) {
			flex-direction: row;
		}
		.form-group {
			width: 100%;
			margin-bottom: 1rem;
			@media screen and (min-width: 700px) {
				width: 180px;
				margin-right: 1rem;
				// background: red;
			}
			input {
				// background: red;
				width: 100%;
				@media screen and (min-width: 700px) {
					width: 180px;
				}
			}
		}
	}
	.container-btn {
		width: 100%;
		// background: red;
		display: flex;
		justify-content: flex-end;
		button {
			width: 100%;
			@media screen and (min-width: 700px) {
				width: auto;
			}
		}
	}
}
.icon-arrow-calendar {
	cursor: pointer;
	background: #7367f0;
	color: #fff;
	margin: 0 0.2rem;
	border-radius: 0.2rem;
	padding: 0.2rem;
}
.icon-arrow-calendar {
	cursor: pointer;
	background: #7367f0;
	color: #fff;
	margin: 0 0.2rem;
	border-radius: 0.2rem;
	padding: 0.2rem;
}

.container-leyend {
	width: 100%;
	@media screen and (min-width: 800px) {
		width: 80%;
	}
	@media screen and (min-width: 1400px) {
		width: 60%;
	}
}
.leyend {
	// margin-left: 1rem;

	display: grid;
	grid-template-columns: 1fr;

	@media screen and (min-width: 800px) {
		grid-template-columns: 1fr 1fr;
	}
	@media screen and (min-width: 1300px) {
		grid-template-columns: 1fr 1fr 1fr;
	}
	.item {
		// margin-left: 1rem;
		display: grid;
		margin: 0.5rem 0;
		grid-template-columns: 1fr 20fr;
		align-items: center;
		.container-circle {
			width: 19.2px;
			// background: red;
			.circle {
				height: 12px;
				width: 12px;
				border-radius: 50%;
				background: beige;
			}
		}
		p {
			margin-bottom: 0;
			margin-left: 0.5rem;
		}
	}
}

.side {
	overflow-x: hidden;
}
</style>
