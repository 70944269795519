<template>
	<div class="container-posturas-supervisor">
		<div class="container-header-modal">
			<div v-if="repeatValue">
				<p class="text-primary font-weight-bolder">Se repiten padrillos para la fecha enviada.</p>
			</div>

			<div class="d-flex align-items-center justify-content-between p-1 p-md-1">
				<b-form-group label="Fecha">
					<b-form-datepicker
						placeholder="Inicio"
						:date-format-options="{
							year: 'numeric',
							month: 'numeric',
							day: 'numeric',
						}"
						locale="es"
						v-model="start_date"
						:min="dayNow"
						v-b-tooltip.hover
						title="Fecha"
						:style="{ width: '180px' }"
					/>
				</b-form-group>
				<button @click="sendChecked" class="btn btn-primary">Guardar</button>
			</div>
		</div>

		<div class="p-1">
			<b-row>
				<b-col md="7">
					<b-card no-body>
						<div class="d-flex p-1 justify-content-between">
							<h2>Sin Programar</h2>
							<b-badge
								variant="primary"
								class="d-flex align-items-center justify-content-center"
								style="width: 30px"
							>
								{{ groups.length }}
							</b-badge>
						</div>
						<div class="p-1">
							<b-row class="mr-1 ml-1 mb-1" style="height: 50px">
								<b-col cols="6" md="6" xl="4">
									<div class="d-flex align-items-center justify-content-center h-100">
										<h3>Padrillos</h3>
									</div>
								</b-col>
								<b-col cols="6" md="6" xl="8">
									<div
										class="d-flex align-items-center justify-content-center h-100"
										style="border-left: 3px solid var(--primary)"
									>
										<h3>Madrillas</h3>
									</div>
								</b-col>
							</b-row>
							<div
								class="w-100 pl-3 pr-1"
								style="overflow-y: scroll; overflow-x: hidden; max-height: 614px"
							>
								<b-overlay :show="isLoading">
									<template v-if="groups.length > 0">
										<transition-group tag="b-row" name="leave" type="transtion-group">
											<b-row
												class="Row w-100 p-1"
												style="background-color: #f6f6f6; border-radius: 10px"
												v-for="(group, index) in groups"
												:key="group.padrillo_plate"
											>
												<b-col cols="6" md="6" xl="4" class="p-0">
													<div
														class="d-flex w-100"
														style="overflow-x: auto; height: 100%; padding: 4px 4px 4px 4px"
													>
														<div
															class="container-specimen p-50"
															style="overflow: hidden; width: 100%"
															:class="[group.repeat ? 'bg-repeat' : '']"
														>
															<NewCardSpecimen
																visible_eye
																:specimen="group.padrillo"
																@tracking-padrillo="toogleTrackingPadrillo($event)"
																@addPadrilloToProgramming="addPadrilloToProgramming"
																:check_padrillo="group"
																:tab="tab"
																:index="index"
															/>
															<div
																class="div-type-ins"
																v-if="group.padrillo.specimen_foot_hand != null"
															>
																<b-badge
																	variant="light-primary"
																	v-b-tooltip.hover
																	:title="
																		resolveTypeIns(
																			group.padrillo.specimen_foot_hand
																		).message
																	"
																>
																	{{
																		resolveTypeIns(
																			group.padrillo.specimen_foot_hand
																		).title
																	}}
																</b-badge>
															</div>
														</div>
													</div>
												</b-col>
												<b-col cols="6" md="6" xl="8">
													<div
														class="d-flex"
														style="
															gap: 1rem;
															overflow-x: auto;
															height: 100% !important;
															padding: 0.5rem;
															width: 100%;
														"
													>
														<div
															class="container-specimen p-50"
															v-for="madrilla in group.madrillas"
															:key="madrilla.plate"
															style="border-color: #b8b2f7; min-width: 200px !important"
														>
															<NewCardSpecimen
																visible_eye
																style="border-color: #b8b2f7; min-height: 7rem"
																v-if="!madrilla.blank"
																:specimen="madrilla"
																hide-select
																@tracking-madrilla="toogleTrackingMadrilla($event)"
															/>
														</div>
													</div>
												</b-col>
											</b-row>
										</transition-group>
									</template>
									<b-row
										v-else
										class="Row w-100 p-1"
										style="background-color: #f6f6f6; border-radius: 10px; height: 150px"
									>
										<div class="d-flex align-items-center justify-content-center w-100">
											<p>No se encontraron pendientes para programar</p>
										</div>
									</b-row>
								</b-overlay>
							</div>
						</div>
					</b-card>
				</b-col>
				<b-col md="5">
					<b-card no-body>
						<div class="d-flex p-1 justify-content-between">
							<h2>Programacion</h2>
							<b-badge
								variant="success"
								class="d-flex align-items-center justify-content-center"
								style="width: 30px"
							>
								{{ programming.length }}
							</b-badge>
						</div>
						<!-- <div class="p-2"> -->
						<!-- <b-row class="mr-1 ml-1 mb-1" style="height: 50px">
								<b-col cols="6" md="4" xl="4">
									<div class="d-flex align-items-center justify-content-center h-100">
										<h3>Padrillos</h3>
									</div>
								</b-col>
								<b-col cols="6" md="8" xl="8">
									<div
										class="d-flex align-items-center justify-content-center h-100"
										style="border-left: 3px solid var(--primary)"
									>
										<h3>Madrillas</h3>
									</div>
								</b-col>
							</b-row> -->

						<div
							class="m-1 p-1"
							style="
								overflow-y: scroll;
								overflow-x: hidden;
								max-height: 680px;
								background-color: #f6f6f6;
								border-radius: 10px;
							"
						>
							<b-overlay :show="isLoading">
								<template v-if="programming.length > 0">
									<transition-group tag="b-row" name="leave" type="transtion-group">
										<!-- <b-row
												class="Row w-100 p-1"
												style="background-color: #f6f6f6; border-radius: 10px"
												v-for="(group, index) in programming"
												:key="group.padrillo_plate"
											> -->
										<b-col
											cols="12"
											md="12"
											lg="12"
											xl="6"
											v-for="(group, index) in programming"
											:key="group.padrillo_plate"
											class="mb-1"
										>
											<div class="d-flex w-100" style="overflow-x: auto; height: 100%">
												<div
													class="container-specimen p-50"
													style="overflow: hidden; width: 100%"
													:class="[group.repeat ? 'bg-repeat' : '']"
												>
													<NewCardSpecimen
														visible_eye
														:specimen="group.padrillo"
														@tracking-padrillo="toogleTrackingPadrillo($event)"
														@removePadrilloToProgramming="removePadrilloToProgramming"
														:check_padrillo="group"
														:tab="tab"
														:index="index"
													/>
													<div
														class="div-type-ins-p"
														v-if="group.padrillo.specimen_foot_hand != null"
													>
														<b-badge
															variant="light-primary"
															v-b-tooltip.hover
															:title="
																resolveTypeIns(group.padrillo.specimen_foot_hand)
																	.message
															"
														>
															{{
																resolveTypeIns(group.padrillo.specimen_foot_hand).title
															}}
														</b-badge>
													</div>
												</div>
											</div>
										</b-col>
										<b-col cols="6" md="8" xl="8" v-if="false">
											<div
												class="d-flex"
												style="
													gap: 1rem;
													overflow-x: auto;
													height: 100% !important;
													padding: 0.5rem;
													width: 100%;
												"
											>
												<div
													class="container-specimen p-50"
													v-for="madrilla in group.madrillas"
													:key="madrilla.plate"
													style="border-color: #b8b2f7; min-width: 200px !important"
												>
													<NewCardSpecimen
														visible_eye
														style="border-color: #b8b2f7; min-height: 7rem"
														v-if="!madrilla.blank"
														:specimen="madrilla"
														hide-select
														@tracking-madrilla="toogleTrackingMadrilla($event)"
													/>
												</div>
											</div>
										</b-col>
										<!-- <hr /> -->
										<!-- </b-row> -->
									</transition-group>
								</template>
								<b-row
									v-else
									class="Row w-100 p-1"
									style="background-color: #f6f6f6; border-radius: 10px; height: 150px"
								>
									<div class="d-flex align-items-center justify-content-center w-100">
										<p>No se agrego ninguna programacion</p>
									</div>
								</b-row>
							</b-overlay>
						</div>
						<!-- </div> -->
					</b-card>
				</b-col>
			</b-row>
		</div>

		<b-modal
			scrollable
			hide-footer
			centered
			size="md"
			v-model="madrillaTracking.show"
			:title="madrillaTracking.title"
			@hide="cleanMadrillaTracking"
		>
			<TrackingMadrillas
				v-if="madrillaTracking.show"
				:madrilla_id="madrillaTracking.madrilla_id"
				:padrillo_id="madrillaTracking.padrillo_id"
			/>
		</b-modal>
	</div>
</template>

<script>
import NewCardSpecimen from "./NewCardSpecimen.vue"
import fertilityService from "../fertilidad.service"
import moment from "moment"
import { mapMutations } from "vuex"
import SpecimenPlate from "@/components/specimens/SpecimenPlate.vue"
import TrackingMadrillas from "./TrackingMadrillas.vue"

export default {
	components: {
		NewCardSpecimen,
		SpecimenPlate,
		TrackingMadrillas,
	},
	props: {
		tab: { type: Number, default: 0 },
	},
	data: () => ({
		groups: [],
		programming: [],
		isLoading: false,
		start_date: moment(new Date()).format("YYYY-MM-DD"),
		repeatValue: false,
		madrillaTracking: {
			show: false,
			title: null,
			data: [],
			id: null,
			padrillo_id: null,
			madrilla_id: null,
		},
	}),
	computed: {
		dayNow() {
			return moment().add(1, "days").toDate()
		},
	},
	methods: {
		...mapMutations("encasteInsemination", ["GET_COUNTER_PENDINGS"]),
		resolveTypeIns(status) {
			let title = { title: "", message: "" }
			switch (status) {
				case 1:
					title.title = "N"
					title.message = "Natural"
					break
				case 2:
					title.title = "P"
					title.message = "Pisa en mano"
					break
				default:
					title.title = "A"
					title.message = "Artificial"
					break
			}
			return title
		},
		async getGroups() {
			this.isLoading = true
			this.programming = []
			const { data } = await fertilityService.getPosturesToInsemination({ search: this.search })
			this.groups = data.map((group, index) => {
				return {
					...group,
					checked: false,
					originalIndex: index,
				}
			})
			this.isLoading = false
		},
		async sendChecked() {
			const { isConfirmed } = await this.showConfirmSwal()
			if (!isConfirmed) return
			let checkedPadrillos = this.programming.filter((group) => group.checked)
			checkedPadrillos = checkedPadrillos.map((group) => group.padrillo.id)
			this.isPreloading()
			const { data, status, message } = await fertilityService.insertSupervisorSelection({
				padrillos: checkedPadrillos,
				start_date: this.start_date,
			})
			this.isPreloading(false)

			if (data.length == 0 && status) {
				await this.getGroups()

				this.$emit("refreshTabPosturas")
			} else {
				if (status) {
					this.showToast(
						"warning",
						"top-right",
						"Inseminación",
						"WarningIcon",
						"Se encontró programaciones repetidas."
					)

					const ids = data.map((it) => it[0])

					this.isLoading = true
					// const { data: dt } = await fertilityService.getPosturesToInsemination({ search: this.search })
					// this.programming = dt.map((group) => {
					// 	return {
					// 		...group,
					// 		checked: false,
					// 		repeat: ids.includes(group.padrillo.id),
					// 	}
					// })

					this.programming = this.programming.map((group) => {
						return {
							...group,
							// checked: false,
							repeat: ids.includes(group.padrillo.id),
						}
					})
					this.repeatValue = true
					this.isLoading = false

					setInterval(() => {
						this.repeatValue = false
					}, 3000)
				} else {
					this.showToast("warning", "top-right", "Inseminación", "WarningIcon", message)
				}
			}

			this.GET_COUNTER_PENDINGS()
		},
		async toogleTrackingMadrilla({ postura_id, title, madrilla }) {
			// console.log('ADD', madrilla);
			this.madrillaTracking.show = true
			this.madrillaTracking.title = `Historial / ${madrilla.alias ? madrilla.alias : ""} (${madrilla.plate})`

			this.madrillaTracking.madrilla_id = madrilla.id
		},
		async toogleTrackingPadrillo({ postura_id, title, padrillo }) {
			// console.log('ADD', madrilla);
			this.madrillaTracking.show = true
			this.madrillaTracking.title = `Historial / ${padrillo.alias ? padrillo.alias : ""} (${padrillo.plate})`

			this.madrillaTracking.padrillo_id = padrillo.id
		},
		cleanMadrillaTracking() {
			this.madrillaTracking = {
				show: false,
				title: null,
				data: [],
				id: null,
				madrilla_id: null,
				padrillo_id: null,
			}
		},

		addPadrilloToProgramming(index) {
			const group = this.groups[index]
			this.groups.splice(index, 1)
			this.insertAt(this.programming, group.originalIndex, group)
		},
		removePadrilloToProgramming(index) {
			const group = this.programming[index]
			this.programming.splice(index, 1)
			this.insertAt(this.groups, group.originalIndex, group)
		},
		insertAt(array, index, group) {
			array.splice(index, 0, group)
		},
	},
	async created() {
		await this.getGroups()
	},
	watch: {
		// trigger(old, val) {
		// 	this.sendChecked();
		// },
	},
}
</script>

<style lang="scss" scoped>
.div-type-ins {
	position: relative;
	top: -31px;
	right: -271px;
}
.div-type-ins-p {
	position: relative;
	top: -31px;
	right: -287px;
}
.container-header-modal {
	position: sticky;
	top: 0;
	background: white;
	z-index: 100;
	box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
}
.Row {
	padding-bottom: 1rem;
	margin-bottom: 1rem;
}
.container-specimen {
	// width: 15rem;
	// min-width: 15rem;
	// overflow-x: auto;
	// padding: .5rem;
	// border: 1px solid rebeccapurple;
	// overflow: hidden;
	background: white;
	border-radius: 12px;
	box-shadow: 0 4px 11px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
	// box-shadow: 0 25px 50px -12px rgb(0 0 0 / 0.25);
	border-bottom: 6px solid var(--primary);
}

.container-posturas-supervisor {
	@media screen and (min-width: 500px) {
		// padding: 1rem;
	}
	.header {
		// position: sticky;
		// top: 100px;
		// z-index: 100;
	}
}
.bg-repeat {
	// background: #dcb3fa;
	border: 5px solid var(--warning);
	// opacity: .5;
}
</style>
<style scoped>
.closeButton {
	display: block;
	position: absolute;
	top: -10px;
	right: -5px;
}
.leave-enter-active,
.leave-leave-active {
	transform-origin: 50% 50%;
	transition: transform 0.4s cubic-bezier(0.61, 1, 0.88, 1);
	transition-property: transform, opacity;
}
.leave-enter,
.leave-leave-to {
	transform: scale(0) rotate(-5deg);
}
</style>
