var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"mx-2 m-2 position-relative"},[_c('b-row',[_c('b-col',{staticClass:"d-flex align-items-center justify-content-start justify-content-sm-start",attrs:{"cols":"12","md":"12","lg":"6"}},[_c('span',{staticClass:"text-muted"},[_vm._v(" Mostrando "+_vm._s(_vm.start_page)+" a "+_vm._s(_vm.to_page)+" de "+_vm._s(_vm.total_data)+" registros ")])]),_c('b-col',{staticClass:"flex-nowrap",attrs:{"cols":"12","md":"12","lg":"6"}},[_c('div',{staticClass:"flex flex-wrap cross-center w-full gap-x-3 xt-search-input justify-content-end"},[_c('div',{staticClass:"xt-search-input__paginate"},[_c('b-pagination',{attrs:{"total-rows":_vm.total_data,"per-page":_vm.perpage,"first-number":"","last-number":"","prev-class":"prev-item","next-class":"next-item"},scopedSlots:_vm._u([{key:"prev-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon","size":"18"}})]},proxy:true},{key:"next-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronRightIcon","size":"18"}})]},proxy:true}]),model:{value:(_vm.current_page),callback:function ($$v) {_vm.current_page=$$v},expression:"current_page"}})],1)])])],1)],1),_c('b-table-simple',{staticClass:"table mb-0",style:({ minHeight: '70vh' }),attrs:{"sticky-header":""}},[_c('b-thead',[_c('b-tr',{staticClass:"text-center"},[_c('b-th',{staticClass:"p-50"},[_vm._v("#")]),_c('b-th',{staticClass:"p-50"},[_vm._v(" "+_vm._s(_vm.madrilla_id ? 'Padrillos' : 'Madrillas')+" ")]),_c('b-th',{staticClass:"p-50"},[_vm._v("Fecha")]),_c('b-th',{staticClass:"p-50"},[_vm._v("Estado")])],1)],1),_c('b-tbody',[_vm._l((_vm.items),function(row,i){return _c('b-tr',{key:row.value,staticClass:"text-center"},[_c('b-td',{staticClass:"p-50"},[_vm._v(" "+_vm._s(i + 1)+" ")]),_c('b-td',[(_vm.madrilla_id)?_c('SpecimenPlate',{attrs:{"specimen":{
							id: row.padrillo.id,
							plate: row.padrillo.plate,
							alias: row.padrillo.alias,
							thumb: row.padrillo.thumb,
							image: row.padrillo.url
						}}}):_vm._e(),(_vm.padrillo_id)?_c('SpecimenPlate',{attrs:{"specimen":{
							id: row.madrilla.id,
							plate: row.madrilla.plate,
							alias: row.madrilla.alias,
							thumb: row.madrilla.thumb,
							image: row.madrilla.url
						}}}):_vm._e()],1),_c('b-td',{staticClass:"p-50"},[_vm._v(" "+_vm._s(_vm._f("myGlobalDayShort")(row.date))+" ")]),_c('b-td',{staticClass:"p-50",class:row.status_id == 2 ? 'text-danger' : ''},[_vm._v(" "+_vm._s(row.status)+" ")])],1)}),(_vm.items.length == 0)?_c('b-tr',{staticClass:"text-center font-weight-bold"},[_c('b-td',{attrs:{"colspan":"4"}},[_vm._v("No hay datos para mostrar")])],1):_vm._e()],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }