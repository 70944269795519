var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.type === 'madrilla')?_c('div',{staticClass:"d-flex justify-content-center gap-x-1"},[(_vm.info.specimen_carnivorous === 1)?_c('FeatherIcon',{staticClass:"text-danger",class:{ 'cursor-pointer': !_vm.disabled },attrs:{"icon":"CheckCircleIcon","size":"20"},on:{"click":_vm.changeStatus}}):_c('FeatherIcon',{class:{ 'text-primary': _vm.dashboard, 'text-secondary': !_vm.dashboard, 'cursor-pointer': !_vm.disabled },attrs:{"icon":"CircleIcon","size":"20"},on:{"click":_vm.changeStatus}}),(_vm.info.view_tracking_specimen_carnivorous === 1)?_c('FeatherIcon',{staticClass:"text-secondary",class:{ 'cursor-pointer': true },attrs:{"icon":'InfoIcon',"size":"20"},on:{"click":_vm.openTracking}}):_vm._e()],1):(_vm.type === 'padrillo')?_c('div',{staticClass:"d-flex align-items-center gap-x-3"},[_c('div',{staticClass:"d-flex flex-column justify-content-start gap-y-1"},[(!_vm.disabled || (_vm.disabled && _vm.info.specimen_foot_hand === 1))?_c('div',{staticClass:"d-flex gap-x-1"},[_c('FeatherIcon',{class:{
						'text-primary': _vm.info.specimen_foot_hand === 1,
						'text-secondary': _vm.info.specimen_foot_hand != 1,
						'cursor-pointer': !_vm.disabled && _vm.info.specimen_foot_hand != 1,
					},attrs:{"icon":("" + (_vm.info.specimen_foot_hand === 1 ? 'CheckCircleIcon' : 'CircleIcon')),"size":"20"},on:{"click":function($event){return _vm.changeStatusForPadrillo(1)}}}),_c('span',{class:{
						'text-primary': _vm.info.specimen_foot_hand === 1,
						'font-weight-bold': _vm.info.specimen_foot_hand === 1,
					}},[_vm._v(" Natural ")])],1):_vm._e(),(!_vm.disabled || (_vm.disabled && _vm.info.specimen_foot_hand === 2))?_c('div',{staticClass:"d-flex gap-x-1"},[_c('FeatherIcon',{class:{
						'text-primary': _vm.info.specimen_foot_hand === 2,
						'text-secondary': _vm.info.specimen_foot_hand != 2,
						'cursor-pointer': !_vm.disabled && _vm.info.specimen_foot_hand != 2,
					},attrs:{"icon":("" + (_vm.info.specimen_foot_hand === 2 ? 'CheckCircleIcon' : 'CircleIcon')),"size":"20"},on:{"click":function($event){return _vm.changeStatusForPadrillo(2)}}}),_c('span',{class:{
						'text-primary': _vm.info.specimen_foot_hand === 2,
						'font-weight-bold': _vm.info.specimen_foot_hand === 2,
					}},[_vm._v(" Pisa en mano ")])],1):_vm._e(),(!_vm.disabled || (_vm.disabled && _vm.info.specimen_foot_hand === 3))?_c('div',{staticClass:"d-flex gap-x-1"},[_c('FeatherIcon',{class:{
						'text-primary': _vm.info.specimen_foot_hand === 3,
						'text-secondary': _vm.info.specimen_foot_hand != 3,
						'cursor-pointer': !_vm.disabled && _vm.info.specimen_foot_hand != 3,
					},attrs:{"icon":("" + (_vm.info.specimen_foot_hand === 3 ? 'CheckCircleIcon' : 'CircleIcon')),"size":"20"},on:{"click":function($event){return _vm.changeStatusForPadrillo(3)}}}),_c('span',{class:{
						'text-primary': _vm.info.specimen_foot_hand === 3,
						'font-weight-bold': _vm.info.specimen_foot_hand === 3,
					}},[_vm._v(" Artificial ")])],1):_vm._e()]),(_vm.info.view_tracking_specimen_foot_hand != null && _vm.info.view_tracking_specimen_foot_hand > 0)?_c('FeatherIcon',{staticClass:"text-warning",class:{ 'cursor-pointer': true },attrs:{"icon":'ListIcon',"size":"21"},on:{"click":_vm.openTracking}}):_vm._e()],1):_vm._e(),_c('b-modal',{attrs:{"centered":"","hide-footer":"","title":("Tracking " + (_vm.type === 'padrillo'
				? 'tipo de inseminacion | Padrillo ' + (this.info.alias ? this.info.alias : this.info.plate)
				: 'come huevo'))},model:{value:(_vm.tracking.show),callback:function ($$v) {_vm.$set(_vm.tracking, "show", $$v)},expression:"tracking.show"}},[_c('b-overlay',{attrs:{"show":_vm.tracking.loading}},[_c('b-table-simple',{attrs:{"small":"","sticky-header":"30vh"}},[_c('b-thead',{staticClass:"text-center"},[_c('b-tr',[_c('b-th',[_vm._v("Estado")]),_c('b-th',[_vm._v("Salida")]),_c('b-th',[_vm._v("Entrada")])],1)],1),_c('b-tbody',{staticClass:"text-center"},_vm._l((_vm.tracking.rows),function(row,idx){return _c('b-tr',{key:idx},[_c('b-td',[_vm._v(" "+_vm._s(String(_vm.type == "padrillo" ? _vm.getDescription(row.status_id) : row.name).toUpperCase())+" ")]),_c('b-td',[(row.end_date)?[_c('span',[_vm._v(_vm._s(row.updated_by))]),_c('br'),_c('small',[_vm._v(_vm._s(_vm._f("myGlobalDayShort")(row.end_date)))])]:_c('span',[_vm._v("---")])],2),_c('b-td',[_c('span',[_vm._v(_vm._s(row.created_by.name))]),_c('br'),_c('small',[_vm._v(_vm._s(_vm._f("myGlobalDayShort")(row.created_by.date)))])])],1)}),1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }